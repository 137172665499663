const initialState = {
    data: [],
    total: 1,
    params: {},
  };
  
  const inductionMasterReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_INDUCTION":
        return {
          ...state,
          data: action.data,
        };
        
      case 'GET_INDUCTION_DATA':
        return {
          ...state,
          data: action.data,
        }
      case "CREATE_INDUCTION":
        return { ...state };
      case "UPDATE_INDUCTION":
        return { ...state };
  
      default:
        return { ...state };
    }
  };
  export default inductionMasterReducer;
  