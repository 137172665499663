/**
 * admin header component
 */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import NavListItem from "./NavListItem";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  toggleThirdMenu,
  toggleMenu,
  toggleFourthMenu,
  onLoadToggleMenu,
} from "Redux/actions";

function SidebarContent(props) {
  const menuListReducer = useSelector((state) => state.menuListReducer);
  const dispatch = useDispatch();
  const routes = menuListReducer.navLinks;
  const [navLinks, setNavLinks] = useState(routes);

  const getPlanName = (name) => {
    let newName = name.replace("-", " ");
    return newName;
  };

  useEffect(() => {
    let currentURL = window.location.href;
    let currentIndex;

    for (let i = 0; i < navLinks.length; i++) {
      if (navLinks[i].menu == currentURL.split("/")[4]) {
        currentIndex = i;
      }
    }
    onLoadToggle(currentIndex);
  }, []);
  localStorage.setItem('id:selectedMenuIndex', menuListReducer.selectedMenuIndex);

  const onLoadToggle = (index) => {
    dispatch(onLoadToggleMenu(index));
    setNavLinks(routes);
  };

  const toggleMenufunction = (index) => {
    dispatch(toggleMenu(index));
    setNavLinks(routes);
  };


  const toggleThirdMenuAndCloseSidebar = (index) => {
    dispatch(toggleThirdMenu(index));
    setNavLinks(navLinks);

    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };
  const toggleThirdMenufunction = (index) => {
    dispatch(toggleThirdMenu(index));
    setNavLinks(routes);
  };

  const toggleFourthMenufunction = (fourthindex) => {
    dispatch(toggleFourthMenu(fourthindex));
    setNavLinks(routes);
    if (props.closeSidebar) {
      props.closeSidebar();
    }
  };

  const { closeSidebar } = props;

  // Filter out menu items with permission: true

  // const filteredNavLinks = navLinks.filter((item) => item.permission === true);
  // const filteredNavLinks = navLinks;
  // navLinks
  // console.log("filteredNavLinks", filteredNavLinks)

  // console.log("navLinks", navLinks)
  return (
    <div>
      <Scrollbars autoHide autoHideDuration={100} style={{ height: "80vh" }}>
        <List className="menu-wrap" style={{ padding: 0 }}>
          {navLinks && navLinks.map((Navlink, index) => {
            //In this i am filtering child_routes permission:true for showing sub menu item.
            let childRoutes = null;
            if (Navlink.child_routes && Navlink.child_routes.length > 0) {
              childRoutes = Navlink.child_routes.filter(
                (child) => child.permission === true
              );
            }
            return (
              <NavListItem
                menu={{
                  ...Navlink,
                  child_routes: childRoutes,
                  index: index
                }}
                key={index}
                toggleMenu={() => toggleMenufunction(index)}
                toggleFourthMenu={(e) => toggleFourthMenufunction(e)}
                toggleThirdMenu={(e) => toggleThirdMenufunction(e)}
                toggleThirdMenuAndCloseSidebar={(e) =>
                  toggleThirdMenuAndCloseSidebar(e)
                }
                closeSidebar={closeSidebar}
              />
            );
          })}
        </List>
      </Scrollbars>
    </div>
  );
}

export default SidebarContent;
