const initialState = {
  data: [],
  total: 1,
  params: {},
  orderData: [],
  branchData: [],
  departmentData: [],
  ticketData: [],
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TICKET_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        totalAmountSum: action.totalAmountSum,
      };

    // case "GET_TICKET_ID_DATA":
    //   return {
    //     ...state,
    //     orderData: action.data,
    //   };

    case "GET_DEPARTMENT_DATALIST":
      return {
        ...state,
        departmentData: action.data,
      };

    case "GET_TICKET_CATEGORY_DATA":
      return {
        ...state,
        ticketData: action.data,
      };
    //   case 'GET_DATA_BRANCH_DATA':
    //     return {
    //       ...state,
    //       branchData: action.data
    //     }

    // case "GET_SHIP_DATA":
    //   return {
    //     ...state,
    //     shipData: action.data,
    //   };

    case "CHANGE_STATUS":
      return { ...state };

    default:
      return { ...state };
  }
};
export default ticketsReducer;
