const initialState = {
  data: [],
  total: 1,
  params: {},

}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_LOGIN_REPORT':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'GET_ORDERS_BY_ID_DATA':
      return {
        ...state,
        orderData: action.data
      }
    case 'GET_DATA_BRANCH_DATA':
      return {
        ...state,
        branchData: action.data
      }
    case 'GET_SHIP_DATA':
      return {
        ...state,
        shipData: action.data
      }

    case "CHANGE_STATUS":
      return { ...state };

    default:
      return { ...state }
  }
}
export default orderReducer
