/**
 * Code splitting Components
 * AsyncComponents
 */
import React from "react";
import Loadable from "react-loadable";
import { HulkPageLoader } from "components/GlobalComponents";
const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};
//  Dashboard 1
// const AsyncDashboard1Component = Loadable({
//   loader: () =>
//     permission &&
//       permission.Dashboard !== undefined &&
//       permission.Dashboard.includes("Dashboard")
//       ? import("views/Dashboard1")
//       : import("views/Welcome"),
//   loading: () => <HulkPageLoader />,
//   delay: 3000,
// });

const AsyncWelcomeComponent = Loadable({
  loader: () => import("views/Welcome"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});


// Master user //
const AsyncUserMasterComponent = Loadable({
  loader: () => import("views/Master/UserMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
  loader: () => import("views/UserSettings"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
  loader: () => import("views/Error/404"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
  loader: () => import("views/Error/403"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
  loader: () => import("views/Error/500"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
  loader: () => import("views/SampleForm"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Master banner //
const AsyncBannerMasterComponent = Loadable({
  loader: () => import("views/Master/BannerMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Master Announcement //
const AsyncAnnouncementMasterComponent = Loadable({
  loader: () => import("views/Master/AnnouncementMaster/index"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynDownloadCategory = Loadable({
  loader: () => import("views/Master/DownloadCategory/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynPurchaseComponent = Loadable({
  loader: () => import("views/MaterialManagement/PurchaseMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynPurchaseHistoryComponent = Loadable({
  loader: () => import("views/MaterialManagement/PurchaseHistory"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynPurchaseReportComponent = Loadable({
  loader: () => import("views/MaterialManagement/PurchaseReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynSettingComponent = Loadable({
  loader: () => import("views/Master/Setting/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynNotificationComponent = Loadable({
  loader: () => import("views/Master/Notification/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynProductComponent = Loadable({
  loader: () => import("views/Master/Product/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynDownloadSectionComponent = Loadable({
  loader: () => import("views/Master/DownloadSection/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//list
const AsynLoginReportComponent = Loadable({
  loader: () => import("views/Reports/LoginReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynCustomerListComponent = Loadable({
  loader: () => import("views/Customers/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynBranchSellsReportComponent = Loadable({
  loader: () => import("views/Reports/BranchSellsReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynSellsReportComponent = Loadable({
  loader: () => import("views/Reports/SalesReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsynTicketReportComponent = Loadable({
  loader: () => import("views/Reports/TicketReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynAbundantCartReportComponent = Loadable({
  loader: () => import("views/Reports/AbundantCartReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsynTrendingProductReportComponent = Loadable({
  loader: () => import("views/Reports/TrendingProductReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncEmployeeComponent = Loadable({
  loader: () => import("views/Master/EmployeeMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncDepartmentComponent = Loadable({
  loader: () => import("views/Master/DepartmentMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncVerticalComponent = Loadable({
  loader: () => import("views/Master/VerticalMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncContactCardComponent = Loadable({
  loader: () => import("views/Master/ContactCardMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncTicketCategoryComponent = Loadable({
  loader: () => import("views/Master/TicketCategoryMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncTopicNameComponent = Loadable({
  loader: () => import("views/Master/TopicNameMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncTopicMediaComponent = Loadable({
  loader: () => import("views/Master/TopicMediaMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});
const AsyncFaqComponent = Loadable({
  loader: () => import("views/Master/FaqMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncTownHallComponent = Loadable({
  loader: () => import("views/Master/TownHallMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncQuickLinksComponent = Loadable({
  loader: () => import("views/Master/QuickLinksMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncInductionComponent = Loadable({
  loader: () => import("views/Master/InductionMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

export {
  // AsyncDashboard1Component,
  AsyncUserMasterComponent,
  AsyncUserSettingsComponent,
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  AsyncErrorPage403Component,
  AsyncSampleFormComponent,
  // AsyncFormInformationComponent,
  // AsynCategoryComponent,
  // AsynPromocodeComponent,
  AsynSettingComponent,
  AsynNotificationComponent,
  AsynProductComponent,
  AsynLoginReportComponent,
  AsynCustomerListComponent,
  // AsynProductAvailibilityUpdateComponent,
  AsynBranchSellsReportComponent,
  AsynSellsReportComponent,
  // AsynSubCategoryComponent,
  AsyncBannerMasterComponent,
  // AsynPopupComponent,
  // AsynBlogsComponent,
  // AsynUnitComponent,
  AsynPurchaseComponent,
  AsynPurchaseHistoryComponent,
  AsynPurchaseReportComponent,
  // AsynSecondSubCategoryComponent,
  // AsynSupplierComponent,
  // AsyncBannerMasterComponent,
  // AsynPopupComponent,
  // AsynPurchaseComponent,
  // AsynPurchaseHistoryComponent,
  // AsynPurchaseReportComponent,
  AsynAbundantCartReportComponent,
  AsynTrendingProductReportComponent,
  AsyncWelcomeComponent,
  AsyncEmployeeComponent,
  AsyncDepartmentComponent,
  AsyncVerticalComponent,
  AsyncContactCardComponent,
  AsynDownloadCategory,
  AsynDownloadSectionComponent,
  AsyncAnnouncementMasterComponent,
  AsyncTicketCategoryComponent,
  AsyncTopicNameComponent,
  AsyncTopicMediaComponent,
  AsyncFaqComponent,
  AsynTicketReportComponent,
  AsyncTownHallComponent,
  AsyncQuickLinksComponent,
  AsyncInductionComponent,
};
