const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  // designationData: [],
  // reportingManager: [],
  // change_PasswordData:[],
};

const downloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_DOWNLOAD":
      console.log("Action data is:", action);
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_DOWNLOAD":
      return { ...state };
    case "UPDATE_DOWNLOAD":
      return { ...state };

    default:
      return { ...state };
  }
};
export default downloadReducer;
