const initialState = {
    data: [],
    total: 1,
    params: {},
    topicNamedata: [],
    allData: [],
    // designationData: [],
    // reportingManager: [],
    // change_PasswordData:[],
  };
  
  const quickLinksMasterReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_DATA_QUICK_LINKS":
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "CREATE_QUICK_LINKS":
        return { ...state };
      case "UPDATE_QUICK_LINKS":
        return { ...state };
     
  
      default:
        return { ...state };
    }
  };
  export default quickLinksMasterReducer;
  